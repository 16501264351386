/** routes **/
import {auth} from '@/router/modules/auth';

const NotFound = () => import("@/views/NotFound");

export const ROUTE_NAMES_ROOT = Object.freeze({
  NOT_FOUND: 'Not Found'
});

/** routes without layout **/
export const root = [
  {
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found'
  },
]

export default root.concat(auth);
