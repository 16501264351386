const Verified = () => import('@/views/auth/VerifyEmail');
const ResetPassword = () => import('@/views/auth/ResetPassword');

export const ROUTE_NAMES_AUTH = Object.freeze({
  VERIFY_EMAIL: 'verify_email',
  RESET_PASSWORD: 'reset_password'
});

export const auth = [
  {
    path: '/auth/verify-email/:userId/:emailVerificationToken',
    name: ROUTE_NAMES_AUTH.VERIFY_EMAIL,
    component: Verified
  },
  {
    path: '/auth/reset-password/:resetPasswordId/:resetPasswordToken',
    name: ROUTE_NAMES_AUTH.RESET_PASSWORD,
    component: ResetPassword
  }
];
